import Lottie from "lottie-react";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import error from "../../assets/lottie/maintenance.json";
import {SOCKET} from "../../enum";
import {MixinKey} from "../../Interface";
import SocketManager from "../../service/SocketManager";
import {getURLSearchParams} from "../../util";
import strings from "../internationalization";

export default function MaintenancePage() {
	const navigate = useNavigate();
	useEffect(() => {
		SocketManager.initial().on(SOCKET.IS_START, () => {
			navigate("/?token=" + getURLSearchParams().get("token"))
		});
		SocketManager.initial().emit(SOCKET.REFRESH, {}, (data: MixinKey) => {
			if (!data.isStopped) {
				navigate("/?token=" + getURLSearchParams().get("token"))
			}
		});
	}, [navigate]);
	return (
		<div className="maintenance">
			<div className='m-auto flex justify-center flex-col'>
				<Lottie animationData={error} className={"w-3/6 h-3/4 m-auto"}/>
					<h1 className="text-white font-bold text-center m-auto app-font-bold mt-8 text-lg md:text-2xl">{strings.maintenance}</h1>
					<span className='text-white/70 m-auto text-center app-font-regular text-sm md:text-base w-4/5 mt-4 leading-6 md:leading-7'>{strings.messageMaintenance}</span>
			</div>
		</div>
	)
}