import khmerFlag from "../assets/flag/cambodia.png";
import china from "../assets/flag/china.png";
import laos from "../assets/flag/laos.png";
import thaiFlag from "../assets/flag/thailand.png";
import english from "../assets/flag/united-kingdom.png";
import vietnam from "../assets/flag/vietnam.png";

export function setLocalStore(key: string, value: string) {
  return new Promise((resolve, reject) => {
    window.localStorage.setItem(key, value);
    resolve("done");
    reject("error");
  });
}

export function getLocalStore(key: string): string | null {
  return window.localStorage.getItem(key);
}

export function getURLSearchParams() {
  const queryString = window.location.search;
  return new URLSearchParams(queryString);
}

export function getBgColor(item: string): string {
  if (Number(item) === 1) {
    return "#188d7b";
  } else if (Number(item) === 2) {
    return "#7541fd";
  } else if (Number(item) === 3) {
    return "#ac32ab";
  } else if (Number(item) === 4) {
    return "#296EB4";
  } else if (Number(item) === 5) {
    return "#fccd01";
  } else if (item === "u") {
    return "#6e4bef";
  } else if (item === "o") {
    return "#e7405b";
  }
  return "#ffffff";
}

type flagType = "khmer" | "english" | "china" | "thai" | "vietnam" | "laos";

export function getFlag(name: flagType) {
  let flag = "";
  switch (name) {
    case "khmer":
      flag = khmerFlag;
      break;
    case "english":
      flag = english;
      break;
    case "china":
      flag = china;
      break;
    case "thai":
      flag = thaiFlag;
      break;
    case "vietnam":
      flag = vietnam;
      break;
    case "laos":
      flag = laos;
      break;
  }
  return flag;
}

export function generateResult(lists: any[]): any[] {
  let results: any[] = [];
  let cols: any[] = [];
  let rows: any[] = [];
  let maxCol: number = 6;

  // lists.length
  for (let i = 0; i < lists.length; i++) {
    if (cols.length > 0) {
      if (cols[cols.length - 1].ouValue !== lists[i].ouValue) {
        // take only 6 digit for columns
        let temp = cols.length < maxCol ? cols : cols.slice(0, maxCol);
        // to remain is the value after 6 digit for turning right result
        let remain =
          cols.length > maxCol ? cols.slice(maxCol, cols.length) : [];

        // loop if the temp of col have less than maxCol then replace empty value
        // to replace value when there is a turn right
        for (let indexTemp = 0; maxCol - temp.length; indexTemp++) {
          temp.push("");
        }

        if (rows.length > 0) {
          let isRowEmpty: boolean = false;

          for (let right = 0; right < rows.length; right++) {
            let replaceIndex: number = maxCol - (right + 1);

            let replaceValue: any = temp[replaceIndex];
            if (replaceValue !== "") {
              remain.push(replaceValue);
            }

            // prev col
            let prevLastCol: any[] = results[results.length - 1];
            if (
              prevLastCol.at(-1)?.ouValue !== prevLastCol.at(4)?.ouValue &&
              prevLastCol.at(4)?.ouValue === rows[right][0].ouValue
            ) {
              replaceIndex = 4;
            }

            // replace value by rows first of right
            temp[replaceIndex] = rows[right][0];

            if (rows[right].length === 1) {
              rows[right] = [""];
            } else {
              rows[right].pop();
            }

            isRowEmpty = rows[right][0] === "";
          }

          if (isRowEmpty) {
            rows = [];
          }
        }

        // if the remain value from temp have value then push to rows for turn right
        if (remain.length > 0) {
          rows.push(remain);
        }

        results.push(temp);
        cols = [];
      }
    }

    cols.push(lists[i]);
  }

  if (cols.length > 6) {
    let temp = cols.length < maxCol ? cols : cols.slice(0, maxCol);
    // to remain is the value after 6 digit for turning right result
    let remain = cols.length > maxCol ? cols.slice(maxCol, cols.length) : [];

    cols = temp;
    if (rows.length > 0) {
      if (rows[rows.length - 1][0] === remain[0]) {
        rows[rows.length - 1].push([...rows[rows.length - 1], ...remain]);
      }
    } else {
      rows.push(remain);
    }
  }

  if (cols.length < maxCol) {
    for (let i = 0; maxCol - cols.length; i++) {
      cols.push("");
    }
  }

  results.push(cols);

  if (rows.length > 0) {
    for (let i = 0; i < rows.length; i++) {
      let lastCol = results[results.length - 1];
      for (let j = 0; j < rows[i].length; j++) {
        if (rows[i][j] !== "") {
          let lastItem = rows[i];
          if (lastItem[j] === lastCol[lastCol.length - 1]) {
            results.push(["", "", "", "", "", lastItem[j]]);
          } else {
            const eleIndex = results[results.length - 2].findIndex(
              (ele: any) => ele === lastItem[j]
            );

            if (eleIndex === -1) {
              results[results.length - 1][eleIndex] = lastItem[j];
              results.push(["", "", "", "", "", ""]);
            } else {
              if (
                results[results.length - 2][4] === lastItem[j] &&
                results[results.length - 2][5] !== lastItem[j]
              ) {
                results[results.length - 1][4] = lastItem[j];
              } else {
                results[results.length - 1][lastCol.length - (i + 1)] =
                  lastItem[j];
              }
            }
          }
        }
      }
      rows[i].pop();
    }
  }
  return results;
}

export function generateRangeResult(lists: any[]): any[] {
  let results: any[] = [];
  let cols: any[] = [];
  let rows: any[] = [];
  let maxCol: number = 6;
  // lists.length
  for (let i = 0; i < lists.length; i++) {
    if (cols.length > 0) {
      if (cols[cols.length - 1].rangeValue !== lists[i].rangeValue) {
        // take only 6 digit for columns
        let temp = cols.length < maxCol ? cols : cols.slice(0, maxCol);
        // to remain is the value after 6 digit for turning right result
        let remain =
          cols.length > maxCol ? cols.slice(maxCol, cols.length) : [];

        // loop if the temp of col have less than maxCol then replace empty value
        // to replace value when there is a turn right
        for (let indexTemp = 0; maxCol - temp.length; indexTemp++) {
          temp.push("");
        }

        if (rows.length > 0) {
          let isRowEmpty: boolean = false;

          for (let right = 0; right < rows.length; right++) {
            let replaceIndex: number = maxCol - (right + 1);

            let replaceValue: any = temp[replaceIndex];
            if (replaceValue !== "") {
              remain.push(replaceValue);
            }

            // prev col
            let prevLastCol: any[] = results[results.length - 1];
            if (
              prevLastCol.at(-1)?.ouValue !== prevLastCol.at(4)?.ouValue &&
              prevLastCol.at(4)?.ouValue === rows[right][0].ouValue
            ) {
              replaceIndex = 4;
            }

            // replace value by rows first of right
            temp[replaceIndex] = rows[right][0];

            if (rows[right].length === 1) {
              rows[right] = [""];
            } else {
              rows[right].pop();
            }

            isRowEmpty = rows[right][0] === "";
          }

          if (isRowEmpty) {
            rows = [];
          }
        }

        // if the remain value from temp have value then push to rows for turn right
        if (remain.length > 0) {
          rows.push(remain);
        }

        results.push(temp);
        cols = [];
      }
    }

    cols.push(lists[i]);
  }

  if (cols.length > 6) {
    let temp = cols.length < maxCol ? cols : cols.slice(0, maxCol);
    // to remain is the value after 6 digit for turning right result
    let remain = cols.length > maxCol ? cols.slice(maxCol, cols.length) : [];

    cols = temp;
    if (rows.length > 0) {
      if (rows[rows.length - 1][0] === remain[0]) {
        rows[rows.length - 1].push([...rows[rows.length - 1], ...remain]);
      }
    } else {
      rows.push(remain);
    }
  }

  if (cols.length < maxCol) {
    for (let i = 0; maxCol - cols.length; i++) {
      cols.push("");
    }
  }

  results.push(cols);

  if (rows.length > 0) {
    for (let i = 0; i < rows.length; i++) {
      let lastCol = results[results.length - 1];
      for (let j = 0; j < rows[i].length; j++) {
        if (rows[i][j] !== "") {
          let lastItem = rows[i];
          if (lastItem[j] === lastCol[lastCol.length - 1]) {
            results.push(["", "", "", "", "", lastItem[j]]);
          } else {
            const eleIndex = results[results.length - 2].findIndex(
              (ele: any) => ele === lastItem[j]
            );

            if (eleIndex === -1) {
              results[results.length - 1][eleIndex] = lastItem[j];
              results.push(["", "", "", "", "", ""]);
            } else {
              if (
                results[results.length - 2][4] === lastItem[j] &&
                results[results.length - 2][5] !== lastItem[j]
              ) {
                results[results.length - 1][4] = lastItem[j];
              } else {
                results[results.length - 1][lastCol.length - (i + 1)] =
                  lastItem[j];
              }
            }
          }
        }
      }
      rows[i].pop();
    }
  }
  return results;
}
