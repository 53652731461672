import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    account: "Account",
    password: "Password",
    cashHistory: "Cash History",
    betHistory: "Bet History",
    khmer: "Khmer",
    english: "English",
    china: "China",
    thai: "Thai",
    vietnam: "Vietnam",
    laos: "Laos",
    logOut: "LogOut",
    on: "On",
    off: "Off",
    close: "CLOSE",
    open: "OPEN",
    min: "Min",
    max: "Max",
    o: "O",
    u: "U",
    betList: "Betting List",
    report: "Report",
    time: "Time",
    bet: "Bet",
    amount: "Amount",
    payout: "Payout",
    result: "Result",
    cash: "Cash",
    betAmount: "Bet Amount",
    changePassword: "Change Password",
    newPassword: "New Password",
    submit: "Submit",
    discard: "Discard",
    date: "Date",
    gameId: "Game ID",
    winLose: "win/lose",
    search: "Search",
    channel: "Channel",
    next: "Next",
    previous: "Previous",
    waiting: "Waiting",
    success: "Success",
    inputAmount: "Please enter amount",
    gameClose: "Game is closed",
    all: "All",
    number: "Number",
    noMultiply: "No",
    bonus: "Bonus",
    roundNumber: "Round Number",
    betOverLimits: "Bet Over Limits",
    availableBet: "Amount can bet",
    betUnderLimit: "Bet less then the limit",
    fail: "Fail.",
    loading: "Loading...",
    pleaseFilter: "Please insert a filter",
    oldBalance: "Old",
    newBalance: "New",
    transactionType: "Transaction Type",
    deposit: "Deposit",
    withDraw: "Withdraw",
    rangeResult: "Range Result",
    OUResult: "O/U Result",
    today: "Today",
    yesterday: "Yesterday",
    last7day: "Last 7 Days",
    last30day: "Last 30 Days",
    thisMonth: "This month",
    lastMonth: "Last month",
    cancel: "Cancel",
    apply: "Apple",
    total: "Total",
    accountNoBalance: "Your account has no money. Please deposit money to your account.",
    maintenance: "Maintenance",
    messageMaintenance: "We are currently performing maintenance. Please check back later for the update. "
  },
  laos: {
    account: "ບັນຊີ",
    password: "ລະຫັດຜ່ານ",
    cashHistory: "ປະຫວັດເງິນສົດ",
    betHistory: "ປະຫວັດການເດີມພັນ",
    khmer: "ຂະເໝນ",
    english: "ພາສາອັງກິດ",
    china: "ຈີນ",
    thai: "ໄທ",
    laos: "ລາວ",
    vietnam: "ຫວຽດນາມ",
    logOut: "ອອກ​ຈາກ​ລະ​ບົບ",
    on: "ສຸດ",
    off: "ປິດ",
    close: "ປິດ",
    open: "ເປີດ",
    min: "ຕ່ຳສຸດ",
    max: "ສູງສຸດ",
    o: "O",
    u: "U",
    betList: "ລາຍຊື່ການພະນັນ",
    report: "ລາຍງານ",
    time: "ເວລາ",
    bet: "ເດີມພັນ",
    amount: "ຈໍາ​ນວນ",
    payout: "ຈ່າຍ",
    result: "ຜົນໄດ້ຮັບ",
    cash: "ເງິນສົດ",
    betAmount: "ຈໍານວນເງິນເດີມພັນ",
    changePassword: "ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ",
    newPassword: "ລະຫັດຜ່ານໃໝ່",
    submit: "ສົ່ງ",
    discard: "ຖິ້ມ",
    date: "ວັນທີ",
    gameId: "ID ເກມ",
    winLose: "ຊະນະ/ສູນເສຍ",
    search: "ຊອກຫາ",
    channel: "ຊ່ອງ",
    next: "ຕໍ່ໄປ",
    previous: "ທີ່ຜ່ານມາ",
    waiting: "ລໍຖ້າ",
    success: "ຄວາມ​ສໍາ​ເລັດ",
    inputAmount: "ກະລຸນາໃສ່ຈໍານວນ",
    gameClose: "ເກມຖືກປິດ",
    all: "ທັງໝົດ",
    number: "ເລກ",
    noMultiply: "ບໍ່",
    bonus: "ໂບນັດ",
    roundNumber: "ຕົວເລກຮອບ",
    betOverLimits: "ການເດີມພັນເກີນຂອບເຂດຈໍາກັດ",
    availableBet: "ຈໍານວນສາມາດວາງເດີມພັນໄດ້",
    betUnderLimit: "ວາງເດີມພັນຫນ້ອຍກວ່າຂອບເຂດຈໍາກັດ",
    fail: "ລົ້ມເຫລວ.",
    loading: "ກຳລັງໂຫຼດ...",
    pleaseFilter: "ກະລຸນາໃສ່ຕົວກອງ",
    oldBalance: "ເກົ່າ",
    newBalance: "ໃຫມ່",
    transactionType: "ປະເພດທຸລະກໍາ",
    deposit: "ຝາກ",
    withDraw: "ຖອນ",
    rangeResult: "ໄລຍະຜົນໄດ້ຮັບ",
    OUResult: "O/U ຜົນໄດ້ຮັບ",
    today: "Today",
    yesterday: "Yesterday",
    last7day: "Last 7 Days",
    last30day: "Last 30 Days",
    thisMonth: "This month",
    lastMonth: "Last month",
    cancel: "Cancel",
    apply: "Apple",
    total: "ທັງໝົດ",
    accountNoBalance: "ບັນຊີຂອງທ່ານບໍ່ມີເງິນ.",
    maintenance: 'ການສ້ອມແປງ',
    messageMaintenance: 'ຂໍຂອບໃຈສະແດງວ່າບໍ່ສາມາດໃຊ້ງານໄດ້ດັ່ງກ່າວ ພວກເຮົາກໍສ້ອມແປງການບໍລິການຂອງພວກເຮົາ ກະລຸນາກັບໄປໃນອອກເບິ່ງການປັບປຸງຂອງພວກເຮົາ.',
  },
  china: {
    account: "帳戶",
    password: "密碼",
    cashHistory: "現金歷史",
    betHistory: "投注歷史",
    khmer: "高棉語",
    english: "英語",
    china: "中國",
    laos: "老撾",
    thai: "泰國",
    vietnam: "越南",
    logOut: "登出",
    on: "在",
    off: "離開",
    close: "關閉",
    open: "打開",
    min: "最小值",
    max: "最大限度",
    o: "大的",
    u: "小的",
    betList: "投注單",
    report: "報告",
    time: "時間",
    bet: "賭注",
    amount: "數量",
    payout: "支出",
    result: "結果",
    cash: "現金",
    betAmount: "投注金額",
    changePassword: "更改密碼",
    newPassword: "新密碼",
    submit: "提交",
    discard: "丟棄",
    date: "日期",
    gameId: "遊戲號碼",
    winLose: "贏/失去",
    search: "搜索",
    channel: "渠道",
    next: "下一個",
    previous: "以前的",
    waiting: "等待",
    success: "成功",
    inputAmount: "請輸入金額",
    gameClose: "遊戲關閉",
    all: "全部",
    number: "數字",
    noMultiply: "不",
    bonus: "獎金",
    roundNumber: "輪數",
    betOverLimits: "投注超過限額",
    availableBet: "可投注金額",
    betUnderLimit: "下注低於限額",
    fail: "失敗",
    loading: "加載中...",
    pleaseFilter: "請插入過濾器",
    oldBalance: "老的",
    newBalance: "新的",
    transactionType: "交易類型",
    deposit: "訂金",
    withDraw: "提取",
    rangeResult: "範圍結果",
    OUResult: "O/U 結果",
    today: "Today",
    yesterday: "Yesterday",
    last7day: "Last 7 Days",
    last30day: "Last 30 Days",
    thisMonth: "This month",
    lastMonth: "Last month",
    cancel: "Cancel",
    apply: "Apple",
    total: "全部的",
    accountNoBalance: '您的帳戶沒有錢。 請將錢存入您的帳戶。',
    maintenance: '維護',
    messageMaintenance: '很抱歉給您帶來不便，我們正在維護。 請稍後再回來。',
  },
  viet: {
    account: "tài khoản",
    password: "mật khẩu",
    cashHistory: "Lịch sử tiền mặt",
    betHistory: "Lịch sử đặt cược",
    khmer: "khmer",
    english: "Tiếng Anh",
    china: "Trung Quốc",
    thai: "tiếng Thái",
    laos: "Nước Lào",
    vietnam: "Việt Nam",
    logOut: "Đăng xuất",
    on: "TRÊN",
    off: "Tắt",
    close: "đóng",
    open: "mở",
    min: "tối thiểu",
    max: "tối đa",
    o: "O",
    u: "U",
    betList: "Danh sách cá cược",
    report: "Báo cáo",
    time: "Thời gian",
    bet: "Cá cược",
    amount: "Số lượng",
    payout: "xuất chi",
    result: "Kết quả",
    cash: "Tiền mặt",
    betAmount: "Số tiền đặt cược",
    changePassword: "Đổi mật khẩu",
    newPassword: "mật khẩu mới",
    submit: "Được rồi",
    discard: "Hủy bỏ",
    date: "ngày",
    gameId: "mã số game",
    winLose: "thắng/thua",
    search: "tìm kiếm",
    channel: "kênh",
    next: "Kế tiếp",
    previous: "trước",
    waiting: "chờ",
    success: "thành công",
    inputAmount: "Vui lòng nhập số tiền",
    gameClose: "Trò chơi đã đóng",
    all: "Tất cả",
    number: "con số",
    noMultiply: "KHÔNG",
    bonus: "thưởng",
    roundNumber: "số tròn",
    betOverLimits: "Đặt cược vượt quá giới hạn",
    availableBet: "Số tiền có thể đặt cược",
    betUnderLimit: "Đặt cược ít hơn giới hạn",
    fail: "Thất bại",
    loading: "Đang tải...",
    pleaseFilter: "Vui lòng chèn bộ lọc",
    oldBalance: "cũ",
    newBalance: "mới",
    transactionType: "Loại giao dịch",
    deposit: "tiền gửi",
    withDraw: "rút",
    rangeResult: "Phạm vi kết quả",
    OUResult: "O/U Kết quả",
    today: "Today",
    yesterday: "Yesterday",
    last7day: "Last 7 Days",
    last30day: "Last 30 Days",
    thisMonth: "This month",
    lastMonth: "Last month",
    cancel: "Cancel",
    apply: "Apple",
    total: "tổng cộng",
    accountNoBalance: "Tài khoản của bạn không có tiền. Vui lòng gửi tiền vào tài khoản của bạn.",
    maintenance: 'Bảo trì',
    messageMaintenance: 'Xin lỗi vì sự bất tiện này, chúng tôi đang bảo trì. Vui lòng quay lại sau.',
  },
  km: {
    account: "គណនី",
    password: "ពាក្យសម្ងាត់",
    cashHistory: "ប្រវត្តិសាច់ប្រាក់",
    betHistory: "ប្រវត្តិភ្នាល់",
    khmer: "ខ្មែរ",
    english: "ភាសាអង់គ្លេស",
    china: "ចិន",
    laos: "ឡាវ",
    thai: "ថៃ",
    vietnam: "វៀតណាម",
    logOut: "ចាកចេញ",
    on: "បើក",
    off: "បិទ",
    close: "បិទ",
    open: "បើក",
    min: "តិចបំផុត",
    max: "និងច្រើនបំផុត",
    o: "ធំ",
    u: "តូច",
    betList: "តារាងភ្នាល់",
    report: "រាយការណ៍",
    time: "ម៉ោង",
    bet: "ភ្នាល់",
    amount: "ទឹកប្រាក់",
    payout: "អត្រាសង",
    result: "លទ្ធផល",
    cash: "សង",
    betAmount: "ចំនួនទឹកប្រាក់ភ្នាល់",
    changePassword: "ផ្លាស់ប្តូរពាក្យសម្ងាត់",
    newPassword: "ពាក្យសម្ងាត់​ថ្មី",
    submit: "យល់ព្រម",
    discard: "បោះបង់",
    date: "កាលបរិច្ឆេទ",
    gameId: "លេខសម្គាល់",
    winLose: "ឈ្នះ/ចាញ់",
    search: "ស្វែងរក",
    channel: "ឆានែល",
    next: "បន្ទាប់",
    previous: "មុន",
    waiting: "រង់ចាំ",
    success: "ជោគជ័យ",
    inputAmount: "សូមបញ្ចូលចំនួនទឹកប្រាក់",
    gameClose: "ហ្គេមត្រូវបានបិទ",
    all: "ទាំងអស់",
    number: "លេខ",
    noMultiply: "មិនមាន",
    bonus: "គុណ",
    roundNumber: "លេខជុំ",
    betOverLimits: "ភ្នាល់លើសចំនួនទឹកប្រាក់ដែលបានកំណត់",
    availableBet: "ទឹកប្រាក់អាចភ្នាល់",
    betUnderLimit: "ភ្នាល់តិចជាងការកំណត់",
    fail: "បរាជ័យ",
    loading: "រង់ចាំ...",
    pleaseFilter: "សូមបញ្ចូលតម្រង",
    oldBalance: "ចាស់",
    newBalance: "ថ្មី",
    transactionType: "ប្រភេទប្រតិបត្តិការ",
    deposit: "ដាក់ប្រាក់",
    withDraw: "ដកប្រាក់",
    rangeResult: "ជួរលទ្ធផល",
    OUResult: "តូច/ធំ លទ្ធផល",
    today: "Today",
    yesterday: "Yesterday",
    last7day: "Last 7 Days",
    last30day: "Last 30 Days",
    thisMonth: "This month",
    lastMonth: "Last month",
    cancel: "Cancel",
    apply: "Apple",
    total: "សរុប",
    accountNoBalance: 'គណនីរបស់អ្នកមិនមានលុយទេ។ សូមដាក់លុយទៅគណនីរបស់អ្នក។',
    maintenance: 'កំពុងកែសម្រួលប្រព័ន្ធ',
    messageMaintenance: 'បច្ចុប្បន្នយើង កំពុងកែសម្រួលប្រព័ន្ធ, សូមពិនិត្យមើលឡើងវិញនៅពេលក្រោយ សម្រាប់ការអាប់ដេត។',
  },
  thai: {
    account: "บัญชี",
    password: "รหัสผ่าน",
    cashHistory: "ประวัติเงินสด",
    betHistory: "ประวัติการเดิมพัน",
    khmer: "เขมร",
    english: "ภาษาอังกฤษ",
    china: "จีน",
    thai: "ภาษาไทย",
    vietnam: "เวียดนาม",
    logOut: "ออกจากระบบ",
    on: "บน",
    off: "ปิด",
    close: "บน",
    open: "เปิด",
    min: "นาที",
    max: "สูงสุด",
    o: "ใหญ่",
    u: "เล็ก",
    betList: "รายการเดิมพัน",
    report: "รายงาน",
    time: "เวลา",
    bet: "เดิมพัน",
    amount: "จำนวน",
    payout: "การจ่ายเงิน",
    result: "ผลลัพธ์",
    cash: "เงินสด",
    betAmount: "จำนวนเงินเดิมพัน",
    changePassword: "เปลี่ยนรหัสผ่าน",
    newPassword: "รหัสผ่านใหม่",
    submit: "ตกลง",
    discard: "ยกเลิก",
    date: "วันที่",
    gameId: "รหัสเกม",
    winLose: "ชนะ/สูญเสีย",
    search: "ค้นหา",
    channel: "ช่อง",
    next: "ต่อไป",
    previous: "ก่อนหน้า",
    waiting: "ซึ่งรอคอย",
    success: "ความสำเร็จ",
    inputAmount: "กรุณาระบุจำนวนเงิน",
    gameClose: "เกมปิด",
    all: "ทั้งหมด",
    number: "ตัวเลข",
    noMultiply: "เลขที่",
    bonus: "โบนัส",
    roundNumber: "เลขกลม",
    betOverLimits: "เดิมพันเกินขีดจำกัด",
    availableBet: "จำนวนเงินที่สามารถเดิมพันได้",
    betUnderLimit: "เดิมพันน้อยกว่าวงเงิน",
    fail: "ล้มเหลว",
    loading: "กำลังโหลด...",
    pleaseFilter: "กรุณาใส่ตัวกรอง",
    oldBalance: "เก่า",
    newBalance: "ใหม่",
    transactionType: "ประเภทธุรกรรม",
    deposit: "เงินฝาก",
    withDraw: "ถอน",
    rangeResult: "ผลลัพธ์ช่วง",
    OUResult: "ใหญ่/เล็ก ผลลัพธ์",
    today: "Today",
    yesterday: "Yesterday",
    last7day: "Last 7 Days",
    last30day: "Last 30 Days",
    thisMonth: "This month",
    lastMonth: "Last month",
    cancel: "Cancel",
    apply: "Apple",
    total: "ทั้งหมด",
    accountNoBalance: 'บัญชีของคุณไม่มีเงิน โปรดฝากเงินในบัญชีของคุณ',
    maintenance: 'การบำรุงรักษา',
    messageMaintenance: 'ขออภัยในความไม่สะดวก ขณะนี้เรากำลังดำเนินการบำรุงรักษา โปรดกลับมาใหม่ภายหลัง',
  },
});

export default strings;
