export const AccessKey = {
  USER: "USER",
  MUTED: "MUTED",
  LANGUAGE: "LANGUAGE",
  TOTAL_BET_FIGHT_RANG: "TOTAL_BET_FIGHT_RANG",
  TOTAL_BET_FIGHT_O: "TOTAL_BET_FIGHT_O",
  TOTAL_BET_FIGHT_U: "TOTAL_BET_FIGHT_U",
  TOKEN: "TOKEN",
} as const;

export const SOCKET = {
  RESULT: "result",
  GAME_INFO: "gameInfo",
  REFRESH: "refresh",
  RANDOM_NUMBER: "random_number",
  REFRESH_BALANCE: "refresh_balance",
  TIMEOUT: "timeout",
  BONUS_PICKET: "bonus_picker",
  CONNECT_ERROR: "connect_error",
  BET: "bet",
  IS_STOPPED: "isStopped",
  IS_START: "isStart",
  DISCONNECT: "disconnect",
} as const;

export enum GetDataFrom {
  LOTTO,
  SB24,
}

export const Sound = {
  ON: "ON",
  OFF: "OFF",
} as const;