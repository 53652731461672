import Lottie from "lottie-react";
import React, {Component, Suspense} from "react";
import {createBrowserRouter, RouterProvider, useLocation, useNavigate, useParams} from "react-router-dom";
import error from "../../assets/lottie/errorpage.json";
import loading from "../../assets/lottie/Loading.json";
import {AccessKey} from "../../enum";
import {getURLSearchParams} from "../../util";
import MaintenancePage from "../components/MaintenancePage";
import strings from "../internationalization";

const Home = React.lazy(() => import("../pages/Home"));
const Fallback = (): JSX.Element => (
	<div className="bg-primary-1 h-screen flex flex-col w-screen items-center justify-center">
		<img src={process.env.REACT_APP_ICON_URL} className="h-[100px] md:h-[250px] lg:h-[350px] object-contain" alt="logo"/>
		<Lottie animationData={loading} className="h-1/5" />
	</div>
);

function ErrorPage({ message }: { message: string }) {
	return (
		<div className="h-screen flex flex-col w-screen items-center justify-center">
			<Lottie animationData={error} className={"w-1/2 h-1/2"} />
			<h1 className="text-white font-bold">{message}</h1>
		</div>
	);
}

function Router({ WrappedComponent }: any) {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();
	return <WrappedComponent router={{ navigate, location, params }} />;
}

export default class Routes extends Component {
	
	private readonly protectedRoute = createBrowserRouter([
		{
			path: "/",
			element: <Router WrappedComponent={Home} />,
			errorElement: (
				<ErrorPage message="Something went wrong, please contact support." />
			)
		},
		{
			path: "/maintenance",
			element: <MaintenancePage/>,
			errorElement: (
				<ErrorPage message="Something went wrong, please contact support."/>
			)
		},
		
	]);
	
	state = {
		auth: false,
		loading: true
	};
	
	constructor(props: any) {
		super(props);
		if (window.localStorage.getItem(AccessKey.LANGUAGE)) {
			strings.setLanguage(window.localStorage.getItem(AccessKey.LANGUAGE) as string);
		} else strings.setLanguage("km");
	}
	
	componentDidMount(): void {
		
		if (getURLSearchParams().get("token")) {
			let time = setTimeout(() => {
				this.setState({auth: true,loading: false });
				clearTimeout(time)
			}, 1000);
		} else window.location.href = process.env.REACT_APP_MAIN_DOMAIN as string;
		
		const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = process.env.REACT_APP_ICON_URL as string;
		document.getElementsByTagName('head')[0].appendChild(link);
		const meta = document.createElement('meta');
		meta.name = "description";
		meta.content = process.env.REACT_APP_DESCRIPTION as string;
		document.title = process.env.REACT_APP_TITLE as string;
		
	}
	
	render() {
		return this.state.loading ? <Fallback /> : this.state.auth ? (
			<main
				style={{background: process.env.REACT_APP_BG_COLOR}}
				className="w-screen h-screen ">
				<Suspense fallback={<Fallback />}>
					<RouterProvider router={this.protectedRoute} />
				</Suspense>
			</main>
		) : <div/>;
	}
}