import io, {Socket} from "socket.io-client";
import {getURLSearchParams} from "../util";

class SocketManager {
  private static instance: SocketManager;
	private token: string | null = getURLSearchParams().get("token")
  
  private initSocket() {
    if (process.env.REACT_APP_MODE === "development") {
	    return io(`${process.env.REACT_APP_SOCKET_API_DEV}`, {auth: {token: this.token}});
    } else {
      return io(`${process.env.REACT_APP_SOCKET_API_PROD}`, {
        auth: { token: this.token },
      });
    }
  }
  
  public static initial(): Socket {
    if (!SocketManager.instance) {
      SocketManager.instance = new SocketManager();
    }
    return SocketManager.instance.initSocket();
  }
}

export default SocketManager;
